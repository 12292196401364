import React from 'react'
import SEO from '../components/seo'


const PrivacyPage = () => {
  return (
    <>
      <SEO title="Política de Privacidad" lang="es" />
      <div className="privacy-container">
        <h1>Aviso de Privacidad</h1>
        <p>Para Gutiérrez F Studio SA de CV. con domicilio en Prado Norte 225, Lomas de Chapultepec, Miguel Hidalgo, 11000, CDMX. (en adelante “GFS”) el tratamiento legítimo, controlado e informado de sus datos personales es de vital importancia. Por ello, le reiteramos nuestro compromiso con la privacidad y su derecho a la autodeterminación informativa.</p>
        <p>El presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento que se les dará a sus datos personales cuando los mismos sean tratados, recabados, utilizados, almacenados y/o transferidos por “GFS”, de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento, Lineamientos y demás disposiciones normativas aplicables y vigentes (en adelante la “Ley”).</p>
        <h2>DATOS PERSONALES</h2>
        <p>Para las finalidades señaladas en el presente aviso de privacidad, se informa que sus datos personales de identificación son recabados y tratados:</p>
        <ol className="bullet-roman">
          <li>cuando Usted nos los proporciona directamente y/o</li>
          <li>a través de la interacción y comunicaciones que tenga con nuestro sitio web.</li>
        </ol>
        <p>Para efectos del presente aviso de privacidad se entiende por:</p>
        <ul>
          <li>Datos personales y/o de identificación: Cualquier información concerniente a una persona física identificada o identificable. Los datos personales que recopilaremos de usted son: nombre, teléfono, correo electrónico, ubicación, entre otros.</li>
        </ul>
        <p>Además, “GFS” recaba y almacena información a través del ingreso a su sitio Web, tal información está relacionada con la dirección IP/nombre de dominio del visitante, comportamiento y el tiempo de estadía en el sitio Web, herramientas utilizadas, tipo de navegador y sistema operativo entre otros. Dicha información se obtiene y almacena con el fin de medir la actividad del sitio e identificar tendencias sobre navegación que no son atribuibles a un individuo en específico. La información anteriormente señalada se recopila a través de las “cookies”, así como otros medios y mecanismos tecnológicos, tales como las etiquetas de píxel, los web bugs, enlaces en correos electrónicos, web beacons (etiquetas de Internet, etiquetas de píxel y clear GIFs), entre otros. La mayoría de los exploradores le permitirán eliminar el uso de cookies, bloquear la aceptación de las cookies o recibir una advertencia antes de almacenar cookies. Le sugerimos consultar las instrucciones de su explorador para utilizar las “cookies”.</p>
        <h2>FINALIDADES Y/O USOS DE LOS DATOS PERSONALES</h2>
        <p>Sus datos personales se utilizarán para finalidades que señalan el presente Aviso de Privacidad o sus actualizaciones que, en su momento, se pongan a su disposición y en específico para:</p>
        <ul>
          <li>Identificarlo y permitir su interacción con el Sitio Web.</li>
          <li>Creación y administración de su cuenta del Usuario</li>
          <li>Responder a sus consultas, dudas y/o aclaraciones</li>
          <li>Atender sus opiniones, quejas y/o sugerencias.</li>
          <li>Ordenar, catalogar, clasificar, dividir o separar y almacenar los datos personales dentro de los sistemas y archivos de “GFS”.</li>
          <li>Realizar el envío de información de novedades, noticias, boletines, foros, etc.</li>
          <li>Llevar un registro histórico de la información, con fines de satisfacción al Usuario desarrollando análisis sobre los intereses y necesidades, brindando de esta manera un mejor servicio.</li>
          <li>Para fines comerciales, mercadotécnicos, publicitarios y/o de prospección comercial, para otorgar beneficios y determinar la calidad de nuestros productos y servicios o de otras empresas pertenecientes al mismo grupo corporativo, pudiendo al efecto ser transferidos a las mismas y/o a terceros o prestadores de servicios publicitarios con quien se tenga alguna relación jurídica con el objeto de proporcionar dichos servicios, así como a operadores de servicios que tengan a su cargo las plataformas para brindarlos; podremos contactarlo y hacerle llegar comunicaciones mediante el envío de mensajes SMS, correos electrónicos y llamadas telefónicas, realizar encuestas, participar en programas de beneficios, programas de lealtad, entrega de premios y otras promociones, medir comportamiento de padecimientos y adherencia a tratamientos, desarrollar nuevos productos y servicios, conocer el mercado y el desempeño de la empresa dentro de éste, brindar servicios de atención al cliente, registrarlo en concursos, eventos, congresos o simposios, y registrarlo para recibir información de interés de “GFS”. Asimismo, podremos registrarlo en los programas en los que se ha inscrito voluntariamente y enviarle información solicitada o información de “GFS” que consideremos pueda ser de su interés, responder sus dudas o comentarios, creación y administración de su cuenta, cuando decide crearla.</li>
        </ul>
        <p>Se hace de su conocimiento que sus datos personales según su propia naturaleza, serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades descritas en el Aviso de Privacidad que se ponga a su disposición y en su caso conforme lo que establecen las disposiciones legales respectivas y se conservarán por el tiempo necesario para realizar los fines específicos propios de su naturaleza o en su defecto, hasta en tanto la normatividad aplicable al caso concreto ordene su conservación.</p>
        <h2>MEDIDAS DE SEGURIDAD</h2>
        <p>“GFS” ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. En particular, contamos con un Área de Datos Personales, convenios de transferencia, una política de privacidad, capacitación a nuestros empleados, acceso restringido a información personal y a bases de datos que las contienen sólo a usuarios autorizados y cláusulas contractuales.</p>
        <h2>TRANSFERENCIAS DE DATOS PERSONALES</h2>
        <p>Sus datos personales podrán ser transferidos a las siguientes personas físicas o morales:</p>
        <ul>
          <li>Autoridades a las que debamos hacerlo en virtud de una ley vigente que así lo ordene, con la finalidad de cumplir con la normatividad correspondiente.</li>
          <li>Para el supuesto de que “GFS” estime necesario contratar servicios de almacenamiento de información en la nube con terceros, siempre que estos cumplan con la legislación vigente aplicable y garanticen contar con medidas de seguridad iguales o superiores a las requeridas por la Ley, su reglamento y la autoridad competente</li>
          <li>A empresas cuya actividad preponderante sea la de brindar servicios comerciales, mercadotécnicos, publicitarios y/o de prospección comercial mediante diversas plataformas de comunicación, siempre con apego a las disposiciones del presente aviso de privacidad y la Ley.</li>
        </ul>
        <h2>DERECHOS DEL TITULAR DE LOS DATOS PERSONALES</h2>
        <p>Usted puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos “ARCO”) respecto a sus datos personales y/o sensibles y/o patrimoniales y/o financieros. Asimismo, Usted puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de sus datos personales, siempre y cuando el tratamiento no sea un tratamiento necesario o que resulte de una relación jurídica.</p>
        <h2>LOS DERECHOS ARCO CONSISTEN EN:</h2>
        <ul>
          <li>Acceso. Que se le informe cuáles de sus datos personales están contenidos en las bases de datos de “GFS”, para qué se utilizan, el origen y las comunicaciones que se hayan realizado con los mismos y, en general, las condiciones y generalidades del tratamiento.</li>
          <p>La obligación de acceso se dará por cumplida cuando:</p>
          <ol>
            <li>los datos se pongan a disposición del titular de los datos personales o bien,</li>
            <li>mediante la expedición de copias simples, medios magnéticos, ópticos, sonoros, visuales, holográficos, documentos electrónicos o cualquier otro medio o tecnología que se considere adecuada. </li>
          </ol>
          <li>Rectificación. Que se corrijan o actualicen sus datos personales en caso de que sean inexactos o incompletos.</li>
          <li>Cancelación. Que sus datos personales sean dados de baja, total o parcialmente, de las bases de datos de “GFS”. Esta solicitud podrá dar lugar a un período de bloqueo tras el cual procederá la supresión de los datos.</li>
          <li>Oposición. Oponerse por causa legítima al tratamiento de sus datos personales por parte de “GFS”.</li>
        </ul>
        <h2>EJERCICIO DE LOS DERECHOS ARCO</h2>
        <p>Si desea acceder al contenido de sus datos personales, rectificarlos, cancelarlos u oponerse y/o revocar su consentimiento, hágalo de nuestro conocimiento por medio de un escrito libre presentado en el domicilio en cita o mediante un correo electrónico a la dirección studio@gutierrezf.com , indicando:</p>
        <ul>
          <li>Nombre del titular;</li>
          <li>Domicilio de titular o dirección de correo electrónico para comunicar respuesta a solicitud;</li>
          <li>Documentos que acrediten identidad o personalidad para presentar la solicitud;</li>
          <li>Descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO;</li>
          <li>Cualquier otro elemento que permita la localización de los datos personales y atención a la solicitud.</li>
        </ul>
        <p>Se informa que su solicitud, los plazos para el ejercicio de los derechos ARCO, así como del derecho de revocación, se regirán por lo dispuesto en la Ley, en caso de tener alguna duda o consulta, le agradeceremos se ponga en contacto a nuestro correo de privacidad: studio@gutierrezf.com</p>
        <p>Nuestra Área de Datos Personales velará en todo momento por la protección de su información personal en cumplimiento con la normatividad aplicable quien dará trámite a su solicitud y atenderá cualquier comentario, duda o queja que pudiera tener respecto al tratamiento de su información personal.</p>
        <p>Asimismo, le informamos que el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales es la autoridad encargada de vigilar por la debida observancia de las disposiciones legales en materia de protección de datos personales.</p>
        <h2>REVOCACIÓN DE DERECHOS ARCO</h2>
        <p>Asimismo, Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará la conclusión de su relación con nosotros.</p>
        <h2>MODIFICACIONES O ACTUALIZACIONES AL AVISO DE PRIVACIDAD</h2>
        <p>“GFS” se reserva el derecho de enmendar, modificar o actualizar el presente Aviso de Privacidad o el que haya puesto a su disposición, según sea necesario o conveniente, por ejemplo, para cumplir con cambios a la legislación o cumplir con disposiciones internas de “GFS”. “GFS” pondrá a su disposición los Avisos de Privacidad actualizado de manera personal.</p>
        <h2>CONTACTO</h2>
        <p>Podrá dirigir preguntas o comentarios respecto al Aviso de Privacidad y/o solicitudes de derechos ARCO por medio de un correo electrónico dirigido a la siguiente dirección: studio@gutierrezf.com y/o mediante un escrito dirigido a la siguiente dirección: Prado Norte 225, Lomas de Chapultepec, Miguel Hidalgo, 11000, CDMX.
Mediante el uso, visita y/o su registro como usuario en el sitio web y/o la App, Usted manifiesta su aceptación con el presente Aviso de Privacidad y su autorización para la transferencia de sus datos personales en los términos indicados en el presente, de conformidad con lo dispuesto por la fracción I del artículo 1803 del Código Civil Federal.</p>
      </div>
    </>
  )
}

export default PrivacyPage
